<template>
  <section class="landing">
    <b-card class="header">
      <div class="container">
        <b-row>
          <b-col cols="3">
            <b-link class="navbar-brand d-flex align-items-center" to="/">
              <img class="mr-1" :src="appLogoImage" alt="logo"/>
<!--              <h3 class="brand-text mb-0">-->
<!--                {{ appName }}-->
<!--              </h3>-->
            </b-link>
          </b-col>
          <b-col cols="9" class="text-right">
            <b-link to="/login">
              Sign In
            </b-link>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div class="container">
      <b-row>
        <b-col cols="12">
          <b-card
            no-body
            class="knowledge-base-bg text-center"
            :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
          >
            <b-card-body class="card-body">
              <h2 class="text-primary">
                Nutheorie Statistics Reports
              </h2>
              <b-card-text class="mb-2">
                <!--                <span>Data Cookie’s one-click reporting instantly sends Google and Facebook Ads data into Google Slides.</span>-->
              </b-card-text>

              <b-button variant="primary" to="/login">
                Sign in
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>

</template>

<script>
import {BButton, BCard, BCardBody, BCardText, BCol, BImg, BLink, BRow} from 'bootstrap-vue'
import {$themeConfig} from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BButton,
    BLink,
    BImg,
  },
  setup() {
    // App Name
    let {skin} = useAppConfig()
    const {appName, appLogoImage} = $themeConfig.app
    return {
      appName,
      appLogoImage,
      skin
    }
  },
  created() {
    this.skin = 'light';
  }
}
</script>

<style lang="scss" scoped>
.landing {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
  }

  .navbar-brand {
    img {
      width: 32px;
      height: auto;
    }
  }
}

.knowledge-base-bg {
  background-size: cover;

  .card-body {
    padding: 8rem;

    @media screen and (max-width: 768px) {
      padding: 60px 32px;
    }

    @media screen and (max-width: 425px) {
      padding: 24px 16px;
    }
  }
}

.save-section {
  .card-body {
    padding: 4rem 1rem;

    @media screen and (max-width: 768px) {
      padding: 60px 32px;
    }

    @media screen and (max-width: 425px) {
      padding: 24px 16px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    height: 100%;

    h2, p {
      width: 100%;
      max-width: 600px;
    }
  }
}

.real-time-section {
  padding: 4rem 1rem;

  @media screen and (max-width: 768px) {
    padding: 60px 32px;
  }

  @media screen and (max-width: 425px) {
    padding: 24px 16px;
  }

  img {
    height: 280px;
    width: auto;
    margin-top: -40px;
    max-width: 100%;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    height: 100%;

    h2, p {
      width: 100%;
      max-width: 600px;
      text-align: right;
    }
  }
}

.text-section {
  padding: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 60px 32px;
  }

  @media screen and (max-width: 425px) {
    padding: 24px 16px;
  }

  h2, p {
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
}

.prebuild-section {

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    height: 100%;

    h2, p {
      width: 100%;
      max-width: 600px;
      text-align: left;
    }
  }

  .card-body {
    padding: 4rem 2rem;

    @media screen and (max-width: 768px) {
      padding: 60px 32px;
    }

    @media screen and (max-width: 425px) {
      padding: 24px 16px;
    }
  }

  img {
    height: 280px;
    width: auto;
    margin-top: -40px;
    max-width: 100%;
  }
}

.reasons-section {
  padding: 4rem 2rem;

  @media screen and (max-width: 768px) {
    padding: 60px 32px;
  }

  @media screen and (max-width: 425px) {
    padding: 24px 16px;
  }

  &__text {
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
}

.image {
  max-width: 100%;
}
</style>
